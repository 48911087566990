import _ from 'lodash'
import { DATE_FORMATS, DATE_FORMAT, moment, safeObject } from 'uikit'
import { v4 as uuid } from 'uuid'
import { GROUNDS_TYPE_PROXY } from 'utils/dict'
import { ORGANIZATION_INDIVIDUAL, ORGANIZATION_NOT_INDIVIDUAL } from './dadata'
import { addressMap, addressMapApi } from './address'

export const ORGANIZATION_STEP = 'organization'
export const POINTS_STEP = 'points'
export const USERS_STEP = 'users'
export const ATTACHMENT_STEP = 'attachment'

export const PROPOSITION_REVIEW_STATE = 'on_sign'
export const PROPOSITION_ARCHIVE_STATE = 'archived'
export const PROPOSITION_SIGN_STATE = 'signed'

export const PARTNER_TEMPlATE_URL = '/common/partner_application_template.pdf'
export const PARTNER_SES_AGREEMENT_URL = '/common/partner_application_ses_agreement.pdf'
export const PARTNER_COMMON_AGREEMENT_URL = '/common/partner_application_common_agreement.pdf'

export const currentStep = step => {
  switch (step) {
    case 'organization':
      return ORGANIZATION_STEP
    case 'points':
      return POINTS_STEP
    case 'users':
      return USERS_STEP
    case 'attachment':
      return ATTACHMENT_STEP
    default:
      return 'organization'
  }
}

export const partnerPropositionUserMap = ({ user }) => {
  const lastName = user?.last_name ?? ''
  const firstName = user?.first_name ?? ''
  const patronymic = user?.middle_name ?? ''
  let fullName = lastName && firstName ? `${lastName} ${firstName}` : ''
  if (patronymic) fullName += ` ${patronymic}`

  const passportSeries = user?.passport?.series ?? ''
  const passportNumber = user?.passport?.number ?? ''

  return {
    id: user?._id ?? user?.id,
    firstName,
    lastName,
    patronymic,
    fullName,
    passport: `${passportSeries}${passportNumber}`,
    passportSeries,
    passportNumber,
    passportDate: user?.passport?.issued_on ? moment(user?.passport?.issued_on, DATE_FORMATS).toDate() : null,
    passportCode: user?.passport?.issuer_code ?? '',
    passportBy: user?.passport?.issued_by ?? '',
    birthPlace: user?.birth_place ?? '',
    birthDate: user?.birthday ? moment(user?.birthday, DATE_FORMATS).toDate() : null,
    addressRegistration: user?.address ? addressMap({ address: user?.address }) : null,
    contactPhone: user?.mobile_phone ? user?.mobile_phone.substr(1) : '',
    email: user?.email,

    packageId: user?.pd_agreement?.package_id ?? null,
    scanId: user?.pd_agreement?.scan_id ?? null,
    agreementUrl: user?.pd_agreement?.url ?? '',
    agreementDate: user?.pd_agreement?.uploaded_at ? moment(user?.pd_agreement?.uploaded_at, DATE_FORMATS).toDate() : null,
    points: user?.partner_application_point_ids ?? [],
    isNeedSave: false,
    documentsUpload: _.flow(
      l => _.map(l, bundle => [..._.map(bundle?.scans, scan => ({
        id: scan?.id ?? scan?._id,
        type: _.get(scan, 'common_code', ''),
        name: _.get(scan, 'name', ''),
        fileName: scan?.url ? scan?.url.split('/').pop() : '',
        fileStatus: _.get(scan, 'status', ''),
        fileStatusText: _.get(scan, 'status_text', ''),
        fileUrl: _.get(scan, 'url', ''),
        isRequired: _.get(scan, 'need_upload', false),
        description: _.get(scan, 'description', ''),
        fileSize: _.get(scan, 'file_size', 0)
      }))]),
      l => _.flatten(l)
    )(_.get(user, 'bundles', [])),
    poscansPackageId: user?.poscans_package_id ?? null
  }
}

export const bankAccountMap = ({ account }) => ({
  id: account?.id ?? account?._id ?? '',
  tempId: uuid(),
  bank: {
    bik: account?.bic ?? '',
    inn: account?.inn ?? '',
    kpp: account?.kpp ?? '',
    fullName: account?.full_name ?? '',
    paymentName: account?.payment_name ?? '',
    shortName: account?.short_name ?? '',
    opf: account?.opf ?? ''
  },
  rs: account?.correspondent_account ?? ''
})

export const bankAccountMapApi = ({ account }) => {
  const resolve = {
    bic: account.bank?.bik || '',
    inn: account.bank?.inn || '',
    kpp: account.bank?.kpp || '',
    correspondent_account: account?.rs || '',
    full_name: account.bank?.fullName || '',
    payment_name: account.bank?.paymentName || '',
    short_name: account.bank?.shortName || '',
    opf: account.bank?.opf || ''
  }

  if (account.id) resolve.id = account.id

  return resolve
}

export const partnerPropositionMap = ({ proposition }) => {
  const resolve = {
    step: currentStep(proposition?.step),
    state: proposition?.status,

    // Organization
    organizationName: proposition?.full_name ?? '',
    organizationShortName: proposition?.short_name ?? '',
    organizationOpfFullName: proposition?.full_opf_name ?? '',
    organizationOpfShortName: proposition?.short_opf_name ?? '',
    inn: proposition?.inn ?? '',
    kpp: proposition?.kpp ?? '',
    ogrn: proposition?.ogrn ?? '',
    ogrnDate: proposition?.ogrn_date ? moment(proposition?.ogrn_date, DATE_FORMATS).toDate() : null,
    address: addressMap({ address: proposition?.address }),
    managerFullName: proposition?.managment_fullname ?? '',
    activityType: proposition?.grounds_type ?? null,
    proxyNumber: proposition?.proxy_number ?? '',
    proxyDate: proposition?.proxy_date ? moment(proposition?.proxy_date, DATE_FORMATS).toDate() : null,
    taxType: proposition?.taxation ?? null,
    bankAccounts: _.map(proposition?.banks, account => bankAccountMap({ account })),
    organizationType: proposition?.org_type === 'individual' ? ORGANIZATION_INDIVIDUAL : ORGANIZATION_NOT_INDIVIDUAL
  }

  // POINTS
  const points = _.map(proposition?.points, point => ({
    id: point?.id ?? point?._id,
    tempId: uuid(),
    segment: {
      id: point?.point_segment?._id ?? point?.point_segment?.id,
      name: point?.point_segment?.name ?? ''
    },
    address: point?.address ? addressMap({ address: point?.address }) : null,
    webAddress: point?.web_address,
    bankAccountId: point?.partner_application_bank_id ?? ''
  }))
  if (_.isEmpty(points)) {
    resolve.points = [{
      id: null,
      tempId: uuid(),
      type: null,
      address: resolve?.address,
      webAddress: resolve?.webAddress
    }]
  } else {
    resolve.points = points
  }

  // Users
  resolve.users = _.map(proposition?.users, user => partnerPropositionUserMap({ user }))

  return resolve
}

export const partnerPropositionUserMapApi = ({ user }) => {
  const resolve = {
    first_name: user?.firstName,
    last_name: user?.lastName,
    passport: {
      series: user?.passportSeries,
      number: user?.passportNumber,
      issued_on: moment(user?.passportDate, DATE_FORMATS).format(DATE_FORMAT),
      issuer_code: user?.passportCode,
      issued_by: user?.passportBy
    },
    birth_place: user?.birthPlace,
    birthday: moment(user?.birthDate, DATE_FORMATS).format(DATE_FORMAT),
    address: addressMapApi({ address: user?.addressRegistration }),
    mobile_phone: `7${user?.contactPhone}`,
    email: user?.email,
    partner_application_point_ids: user?.points
  }

  if (user?.patronymic !== '') resolve.middle_name = user?.patronymic

  return resolve
}

export const partnerPropositionMapApi = ({ proposition }) => {
  let resolve = {
    step: proposition?.step
  }

  switch (proposition?.step) {
    case ORGANIZATION_STEP:
      resolve = {
        ...resolve,
        inn: proposition?.inn,
        kpp: proposition?.kpp,
        ogrn: proposition?.ogrn,
        ogrn_date: proposition?.ogrnDate ? moment(proposition?.ogrnDate, DATE_FORMATS).format(DATE_FORMAT) : null,
        full_name: proposition?.organizationName,
        short_name: proposition?.organizationShortName,
        full_opf_name: proposition?.organizationOpfFullName,
        short_opf_name: proposition?.organizationOpfShortName,
        org_type: proposition?.organizationType === ORGANIZATION_INDIVIDUAL ? 'individual' : 'legal',
        taxation: proposition?.taxType,
        banks: _.map(proposition?.bankAccounts, account => bankAccountMapApi({ account }))
      }

      if (proposition?.organizationType === ORGANIZATION_NOT_INDIVIDUAL) {
        resolve.grounds_type = proposition?.activityType
        if (proposition?.activityType === GROUNDS_TYPE_PROXY) {
          resolve.proxy_number = proposition?.proxyNumber
          resolve.proxy_date = moment(proposition?.proxyDate, DATE_FORMATS).format(DATE_FORMAT)
        }
        resolve.managment_fullname = proposition?.managerFullName
      }
      resolve.address = addressMapApi({ address: proposition.address })

      break

    case POINTS_STEP:
      resolve = {
        ...resolve,
        points: _.map(proposition?.points, point => (safeObject({
          id: point.id || null,
          point_segment_id: point?.type,
          address: point?.address ? addressMapApi({ address: point?.address }) : null,
          web_address: point?.webAddress,
          partner_application_bank_id: point?.bankAccountId
        })))
      }
      break
  }

  return resolve
}
