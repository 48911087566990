import _ from 'lodash'
import { addressMap } from './address'

export const dadataAddressMap = r => addressMap({ address: { value: r.value, ...r.data } })

export const dadataFmsMap = r => ({
  value: r.value,
  code: _.get(r.data, 'code'),
  name: _.get(r.data, 'name'),
  regionCode: _.get(r.data, 'region_code'),
  type: _.get(r.data, 'type')
})

export const dadataFioMap = r => ({
  value: r.value,
  source: _.get(r.data, 'source', ''),
  result: _.get(r.data, 'result', ''),
  resultGenitive: _.get(r.data, 'result_genitive', ''),
  resultDative: _.get(r.data, 'result_dative', ''),
  resultAblative: _.get(r.data, 'result_ablative', ''),
  surname: _.get(r.data, 'surname', ''),
  name: _.get(r.data, 'name', ''),
  patronymic: _.get(r.data, 'patronymic', ''),
  gender: _.get(r.data, 'gender', ''),
  qc: _.get(r.data, 'qc', '')
})

export const ORGANIZATION_INDIVIDUAL = 'individual'
export const ORGANIZATION_NOT_INDIVIDUAL = 'not-individual'

export const dadataOrganizationMap = r => {
  const address = (_.get(r.data, 'address', {}))

  return {
    name: _.get(r, 'value', ''),
    shortName: _.get(r.data, 'name.short', '') || _.get(r.data, 'name.full', ''),
    opfFullName: _.get(r.data, 'opf.full', ''),
    opfShortName: _.get(r.data, 'opf.short', ''),
    address: dadataAddressMap(address),
    inn: _.get(r.data, 'inn', ''),
    kpp: _.get(r.data, 'kpp', ''),
    ogrn: _.get(r.data, 'ogrn', ''),
    ogrnDate: new Date(_.get(r.data, 'ogrn_date', '')),
    managerName: _.get(r.data, 'management.name', ''),
    organizationType: _.get(r, 'data.type', false) === 'INDIVIDUAL' ? ORGANIZATION_INDIVIDUAL : ORGANIZATION_NOT_INDIVIDUAL
  }
}

export const dadataBanknMap = r => {
  const address = (_.get(r.data, 'address', {}))

  return {
    name: _.get(r, 'value', ''),
    address: dadataAddressMap(address),
    bik: _.get(r.data, 'bic', ''),
    inn: _.get(r.data, 'inn', ''),
    kpp: _.get(r.data, 'inn', ''),
    fullName: _.get(r.data, 'name.full') || r?.value,
    paymentName: _.get(r.data, 'name.payment'),
    shortName: _.get(r.data, 'name.short'),
    opf: _.get(r.data, 'opf.type')
  }
}
